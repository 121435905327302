@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap");

/* @font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: 600;
} */

@font-face {
  font-family: "NanumGothic";
  src: url(./assets/fonts/NanumGothic-Regular.ttf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "NanumGothic";
  src: url(./assets/fonts/NanumGothic-Regular.ttf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "NanumGothic";
  src: url(./assets/fonts/NanumGothic-Bold.ttf) format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "NanumGothic";
  src: url(./assets/fonts/NanumGothic-ExtraBold.ttf) format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: 500;
}

body {
  margin: 0;
  font-family: "Nanum Gothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 15px; /* 스크롤바의 너비 */
  height: 15px; /* 스크롤바의 너비 */
}

::-webkit-scrollbar-thumb {
  height: 30%; /* 스크롤바의 길이 */
  background: #d9d9d9; /* 스크롤바의 색상 */
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 1); /*스크롤바 뒷 배경 색상*/
  border-radius: 10px;
  margin: 8px;
}

/* 캘린더 인풋에서 키보드로 조작하는 경우를 위해 조건에 따라 input에 type date적용하여 
기본으로 있는 캘린더아이콘을 없애기 위해 추가한 스타일*/
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
