  /* 메뉴가 밑에서 위로 펼쳐질 때 애니메이션 */
  .menu-slide-enter {
    transform: translateY(100%); /* 처음에 화면 아래쪽에 위치 */
    opacity: 0;
  }

  .menu-slide-enter-active {
    transform: translateY(0); /* 위로 슬라이드 */
    opacity: 1;
    transition: transform 300ms ease-out, opacity 300ms ease-out;
  }

  .menu-slide-exit {
    transform: translateY(0); /* 정상 위치 */
    opacity: 1;
  }

  .menu-slide-exit-active {
  transform: translateY(100%); /* 아래로 슬라이드 */
  opacity: 0;
  transition: transform 300ms ease-in, opacity 300ms ease-in;
  }
  
  /* 리스트 아이템 애니메이션 */
  .menu-item-enter {
    opacity: 0;
    transform: translateX(-20%);
  }
  
  .menu-item-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
  }
  
  .menu-item-exit {
    opacity: 1;
    transform: translateX(0);
  }
  
  .menu-item-exit-active {
    opacity: 0;
    transform: translateX(-20%);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
  }
  